body, html {
  height: 100%;
  margin: 0;
  background-color: rgb(45, 45, 116);
}

.App {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  text-align: center;
  margin-top: 0px;
  padding: 0px;
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
  background-color: rgb(45, 45, 116);
}
